* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto';
}

body {
  background-color: #e4e4e4;
}

a:link.externalLink,
a:visited.externalLink,
a:hover.externalLink {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}

a:hover.externalLink {
  text-decoration: none;
}

.MuiButton-contained,
.MuiButton-outlined,
.MuiButton-text {
  text-transform: inherit !important;
}
